<template>
  <div>
    <b-container>
      <b-row align-v="center" align-h="center" class="lvj-login-card">
        <b-col cols="6">
          <b-card>
            <b-img
              src="../assets/logo.png"
              alt="Responsive image"
              width="200"
              fluid
              center
            />
            <b-card-text>
              <b-form v-if="switchForm" @submit="onSubmitLogin">
                <form-input
                  v-model="formLogin.email"
                  label="Email:"
                  placeholder="Digite seu email"
                  type="email"
                  icon="envelope"
                  required
                />

                <form-password
                  v-model="formLogin.password"
                  label="Senha:"
                  placeholder="Digite sua senha"
                  type="password"
                  icon="lock"
                  required
                />

                <b-button type="submit" block variant="primary">
                  ENTRAR
                </b-button>
              </b-form>
              <b-form v-else @submit="onSubmitPass">
                <form-input
                  v-model="formLogin.email"
                  label="Email:"
                  placeholder="Digite seu email"
                  type="email"
                  icon="envelope"
                  required
                />

                <b-button type="submit" block variant="primary">
                  ENVIAR
                </b-button>
              </b-form>
              <div
                class="d-flex justify-content-center align-items-center mt-3"
                style="gap: 0.3rem"
              >
                <span>ou</span>
                <button class="login-button" @click="switchForm = !switchForm">
                  {{ switchForm ? "Esqueci minha senha" : "Realizar login" }}
                </button>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "../components/form/FormInput.vue";
import FormPassword from "../components/form/FormPassword.vue";

export default {
  name: "login",
  components: { FormPassword, FormInput },
  data() {
    return {
      switchForm: true,
      formForgotPass: {
        email: "",
      },
      formLogin: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["login", "forgotPassword"]),

    async onSubmitLogin(event) {
      event.preventDefault();
      await this.login(this.formLogin);
    },
    async onSubmitPass(event) {
      event.preventDefault();
      await this.forgotPassword(this.formLogin);
    },
  },
};
</script>

<style lang="scss" scoped>
.lvj-login-card {
  height: 100vh;
}

.login-button {
  border: none;
  background: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
